import React from 'react'
import Input from '../components/Input'
import TextArea from '../components/TextArea'
import Dropdown from '../components/Dropdown'
import RadioButton from '../components/RadioButton'
import CheckBox from '../components/CheckBox'
const FormControl = (props) => {
    const {control,...rest } = props
switch (control) {
    case "input": return <Input {...rest} />
    case "textarea": return <TextArea {...rest}/>
    case "select": return  <Dropdown {...rest}/>
    case "radio": return <RadioButton {...rest}/>
    case "checkbox": return <CheckBox {...rest} />
    default: return null
}
}

export default FormControl