import React from "react";
import { Field, ErrorMessage } from "formik";
import TextError from "../TextError";
import "./index.css"

const Dropdown = (props) => {
    const { customClassName, label, name, options } = props
    return (
        <div>
            <label htmlFor={name} className={` leading-6 text-gray text-base`}>{label} </label>
            <Field as='select'
                id={name}
                name={name}
                key={name}   // Add the key prop here
                {...props}
                style={{ border: "1px solid #E7E8E5" }}
                className={`${customClassName} h-[56px] bg-white w-full p-[12px 16px] border-none rounded-lg mt-1 relative selectSearch`}
            >
                {
                    options && options.map((option) => {
                        return (
                            <option
                                id={option.id}
                                key={option.id}   // Add the key prop here
                                className="option"
                                value={option.id}
                            >
                                <div className=" font-medium leading-6 text-base border-none outline-none">
                                    {option.name}
                                </div>
                            </option>
                        )
                    })
                }
            </Field>
            <ErrorMessage name={name} component={TextError} />
        </div>
    );
};

export default Dropdown;
