import React from 'react'

function Heading({titel,Description}) {
    return (
        <>
            <div className={` rounded-lg ${titel ? "p-0":"p-3"} ${titel ? "bg-transparent":"bg-gray100"}`}>
                {titel ? <p className='font-bold text-lg leading-[27px] text-black pb-2'> {titel}</p>:null}
                <p className=' font-normal text-sm leading-6 text-gray'>{Description}</p>
            </div>
        </>
    )
}

export default Heading