import React, {useEffect, useRef, useState } from "react";
import Avatar from "../components/Avatar";
import { travl, voctor } from "../assets";
import Heading from "../components/Heading";
import SummaryThanks from "../components/SummaryThanks";
import { useParams } from 'react-router-dom';
import { GTMProvider, useGTMDispatch } from '@elgorditosalsero/react-gtm-hook'


const Thanks = () => {

  const sendDataToGTM = useGTMDispatch();

  const { uuid } = useParams();

  const [booking, setBooking] = useState({});
  const [prod, setProd] = useState({});

  const getEndDate = () => {
    return booking.end;
  };

  const getTotal = () => {
    return booking.totalInt;
  };

  useEffect(() => {
    fetchProductDetails();
  }, []);

  const fetchProductDetails = async () => {
    try {
      const response = await fetch(
        `https://www.travl.se/api/get-booking?uuid=${uuid}`
      );

      const data = await response.json();
      setBooking(data.booking);
      setProd(data.prod);
        
      sendDataToGTM({ 
        event: 'purchase',
        value: data.booking.totalInt,
        totalInt: data.booking.totalInt,
        transaction_id: data.booking.bookNumber,
        currency: 'SEK',
        items: [
          {
            item_name: data.prod.title,
            price: data.booking.totalInt,
            quantity: data.booking.pax
          }
        ]
      })

    } catch (error) {
      console.error(error);
    }
  };
  

  return (
    <>
      <main className="bg-white">
        <div className="mx-auto ">
          <div className="xl:grid grid-cols-12">
            <div
              className=" xl:col-span-8 w-full xl:w-[46rem] mx-auto justify-center
                    xl:justify-items-start "
            >
              <nav className="ml-4 px-0 md:px-3 xl:px-0 lg:ml-0 pb-6 pt-6">
                <img src={travl} alt="travl" />
                <h1 className="font-bold  text-[21px] md:text-[32px] leading-[38.4px] text-black pb-1 pt-5 xl:pt-12 font">
                  Bokningsnummer {booking.bookNumber}
                </h1>
               
              </nav>
              <div className="bg-gray200 rounded-2xl rounded-b-none px-3 xl:p-10 xl:pb-0 m-0 md:m-5 lg:m-0">
                {/* ===========Choose your trip avatar ========== */}
                <div className="">
                  <Avatar logo={voctor} heading="Tack för din bokning!" />
                </div>

              <div className="pt-4">
                <div className="pb-0">
                  <h1 className="text-black leading-7 text-lg font-bold tracking-[0.01em] pb-1">
                  {prod?.title}
                  </h1>
                  <p className=" leading-6 text-gray text-base">
                    {prod?.opt}
                  </p>
                </div>
              </div>

              <div className="pt-4">
                <div className="pb-0">
                  <h1 className="text-black leading-7 text-lg font-bold tracking-[0.01em] pb-1">
                  Datum
                  </h1>
                  <p className=" leading-6 text-gray text-base">
                    {booking?.start} - {booking?.end}
                  </p>
                </div>
              </div>

              <div className="pt-4">
                <div className="pb-0">
                  <h1 className="text-black leading-7 text-lg font-bold tracking-[0.01em] pb-1">
                  Kostnad
                  </h1>
                  <p className=" leading-6 text-gray text-base pb-2">
                    {booking?.total}
                  </p>
                  <Heading Description="Observera att detta är grundpriset som kan justereas om du t.ex valt extranätter, tillval, reseförsäkringar o.s.v. Vi återkommer med en slutlig faktura när vi bekräftar din resa. " />
                </div>
              </div>

              <div className="pt-4">
                <div className="pb-0">
                  <Heading
                     titel="Vad händer nu?"
                     Description="Vi sätter igång och kontrollerar plats på alla boenden.* Detta tar ca 3-5 dagar och när bokningen bekräftas får du faktura och bekräftelse. Kan vi inte bekräfta din resa eller behöver göra ändringar kommer vi kontakta dig."
                   /> 
                   <br></br>
                  </div>
              </div>

              
            </div>
          </div>
                    

            {/* ====================Tour Summary secoutn--------------- */}
            <div className=" col-span-4 w-[300px] max-w-[100%] relative hidden xl:block">
              <div
                className="fixed w-full"
                id="travl"
                style={{ backgroundImage: `url(${prod.img})` }}
              >
                <section className="travl_trip max-w-[100%] absolute w-[450px] md:w-[400] xl:w-[450px] 2xl:w-[430px] bg-white rounded-2xl  md:top-[2.6rem] md:right-[10rem] xl:right-[9rem]">
                  <SummaryThanks
                    included={booking?.included}
                    date={booking.start}
                    endDate={getEndDate}
                    getTotal={getTotal}
                  />
                </section>
                
              </div>
            </div> 
          </div>
        </div>
      </main>
    </>
  );
};

export default Thanks;
