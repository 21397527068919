import React, { useRef, useState, useEffect } from "react";
import { arrow, downarrow } from "../../assets";
import "./index.css";
import moment from "moment";
import Button from "../Button";
import FormControl from "../../FormikControl";

const MbleSummary = ({
  included,
  date,
  endDate,
  getTotal,
  getTotalAmount,
  totalExtraNights,
  validationSchema,
  confirmBookingOption,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const targetRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const [error, setError] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const targetElement = targetRef.current;
      if (targetElement) {
        const { top } = targetElement.getBoundingClientRect();
        setIsVisible(top >= window.innerHeight);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToError = () => {
    const errorElement = document.querySelector(".error");
    if (errorElement) {
      errorElement.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  return (
    <>
      <section className="bg-gray200 rounded-2xl mt-5 relative">
        <div className="pt-8 pb-2 px-6 ">
          {/* =================tour summary================= */}
          <div>
            <div className="pb-6 flex justify-between">
              <div>
                <h1 className="font-bold text-2xl leading-7 text-black pb-1">
                  Din resa
                </h1>
                <p className="font-normal text-base leading-6 text-gray">
                  {moment(date).format("YYYY-MM-DD")} -{" "}
                  {moment(endDate()).format("YYYY-MM-DD")}
                </p>
              </div>
            </div>
            <div>
              <hr className="text-gray100" />
            </div>
          </div>

          {/* ===========Your Impact============= */}
          <div className="py-2" ref={targetRef}>
            <div className="flex justify-between items-center">
              <h1 className="font-medium text-lg leading-7 text-black">
                Vad som ingår
              </h1>
              <div
                className="transition cursor-pointer w-8 h-8 flex justify-center items-center bg-limeGreen rounded-[40px]"
                onClick={() => setIsOpen(!isOpen)}
                style={isOpen ? { transform: "rotate(180deg)" } : {}}
              >
                <img src={downarrow} alt="icon" />
              </div>
            </div>
            {isOpen && (
              <div className="pt-3">
                {/* 1rd-------------------- */}
                {included?.map((included) => (
                  <div className="flex items-center pb-2">
                    <div className="flex items-center mr-2">
                      <div className="w-5 h-5 p-1 rounded-full bg-gray100 flex items-center">
                        <img src={arrow} alt="" className="mx-auto text-gray" />
                      </div>
                    </div>
                    <div className="">
                      <p className="font-normal text-base leading-6 text-gray">
                        {included}
                      </p>
                    </div>
                  </div>
                ))}
                <div className="pt-6 pb-4">
                  <hr className="text-gray100" />
                </div>
              </div>
            )}
          </div>

          <section
            className={`bg-gray200 rounded-2xl pb-6 w-[100%] relative`}
            style={
              isVisible && getTotal() > 0
                ? {
                    position: "fixed",
                    bottom: "0px",
                    maxWidth: "1050px",
                    padding: "0px 2rem",
                    transform: `translate(-24px)`,
                    boxShadow: "-2px -6px #f0f0f0d6",
                  }
                : {}
            }
          >
            <div className="flex justify-between items-center">
              <div className="py-3 ">
                <h1 className="font-medium text-sm md:text-lg leading-4 md:leading-7 text-black pb-0 md:pb-1">
                  Totalt
                </h1>
              </div>
              <div>
                {getTotal() || getTotalAmount() > 0 ? (
                  <h1 className="text-sm md:text-[22px] xl:text-[25px] leading-[38px] font-bold text-green ">
                    {getTotal()}{" "}kr
                    <span className="text-[13px] md:text-[18px] text-gray">
                      {" "}
                     
                    </span>
                  </h1>
                ) : null}

              </div>
            </div>
            {/* <div className="flex justify-between items-center "> */}
              <FormControl
                  control="checkbox"
                  name="confirmbooking"
                  options={confirmBookingOption}
                />

              {validationSchema.length > 0 && (
                <div className="error text-[red] mb-2">
                  {validationSchema.map((error, index) => (
                    <p key={index}>{error}</p>
                  ))}
                </div>
              )}
              <div>
                {error ? (
                  <small className="error ml-2 mb-1">
                    Du glömde vissa fält
                  </small>
                ) : null}
                <div className="flex justify-center">
                  <Button
                    text="Slutför bokningen"
                    type="submit"
                    onClick={scrollToError}
                  />
                </div>
              </div>

            {/* </div> */}
          </section>
        </div>
      </section>
    </>
  );
};

export default MbleSummary;
