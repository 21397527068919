import React, { useMemo } from "react";
import { arrow, downarrow } from "../../assets";
import "./index.css";
import Heading from "../Heading";
import { useState } from "react";
import moment from "moment";
const SummaryThanks = ({
  included,
  date,
  endDate,
  listOfbikes,
  listOfRooms,
  totalExtraNights,
  getTotal,
  amountOfBikes,
  amountOfRooms,
  amountOfExtraNights,
  wholeAmount,
}) => {
 
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="relative">
      <div className="hidden md:block">
        <div
          className="p-8 rounded-2xl"
          style={{ boxShadow: "0px 8px 48px rgba(0, 0, 0, 0.06)" }}
        >
          {/* =================tour summary================= */}
          <div>
            <div className="pb-6">
              <h1 className="font-bold text-2xl leading-7 text-black pb-1">
                Din resa
              </h1>
              <p className="font-normal text-base leading-6 text-gray">
                {moment(date).format("YYYY-MM-DD")} -
                {moment(endDate()).format("YYYY-MM-DD")}
              </p>
            </div>
            <div>
              <hr className="text-gray100" />
            </div>
          </div>
          {/* ===========Your Impact============= */}
          <div className="py-6">
            <div>
              <div className="flex justify-between items-center">
                <h1 className="font-medium text-lg leading-7 text-black">
                  Vad som ingår
                </h1>
                <div
                  className="transition cursor-pointer w-8 h-8 flex justify-center items-center bg-limeGreen rounded-[40px]"
                  onClick={() => setIsOpen(!isOpen)}
                  style={isOpen ? { transform: "rotate(180deg)" } : {}}
                >
                  <img src={downarrow} alt="icon" />
                </div>
              </div>
              {isOpen && (
                <div className="pt-3">
                  {/* 1rd-------------------- */}
                  {included?.map((included, index) => (
                    <div className="flex items-center pb-2" key={index}>
                      <div className="flex items-center mr-2">
                        <div className="w-5 h-5 p-1 rounded-full bg-gray100 flex items-center">
                          <img
                            src={arrow}
                            alt=""
                            className="mx-auto text-gray"
                          />
                        </div>
                        </div>
                      <div className="">
                        <p className="font-normal text-base leading-6 text-gray">
                          {included}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
            <div className="pt-6">
              <hr className="text-gray100" />
            </div>
            <div className="flex itmems-center justify-between pt-6">
              <div>
                <h1 className="font-medium text-lg leading-7 text-black pb-1">
                  Totalpris
                </h1>
                {/* {getTotalAmount() > 0 ? (
                  <p className="font-normal text-base leading-6 text-gray">
                    {new Intl.NumberFormat("sv", {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                      style: "currency",
                      currency: "SEK",
                    }).format(getTotal())}
                    x {totalAdults}
                  </p>
                ) : null} */}
                {listOfRooms ? (
                  <p className="font-normal text-base leading-6 text-gray">
                    {`  Boende :${
                      listOfRooms?.length > 1 ? "s" : ""
                    } ${amountOfRooms}
                    kr`}
                  </p>
                ) : null}
                {listOfbikes ? (
                  <p className="font-normal text-base leading-6 text-gray">
                    Cykelhyra : {listOfbikes?.length > 1 ? "s" : ""}
                    {amountOfBikes} kr
                  </p>
                ) : null}

                {totalExtraNights ? (
                  <p className="font-normal text-base leading-6 text-gray">
                    Extranätter : {totalExtraNights?.length > 1 ? "s" : ""}
                    {amountOfExtraNights} kr
                  </p>
                ) : null}
                {wholeAmount ? (
                  <p className="font-normal text-base leading-6 text-gray">
                    Tillval / Transfers: {wholeAmount?.length > 1 ? "s" : ""}
                    {wholeAmount} kr
                  </p>
                ) : null}
              </div>
              <div>
                <h1 className=" text-[32px] leading-[38px] font-bold text-green ">
                  {new Intl.NumberFormat("sv", {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                    style: "currency",
                    currency: "SEK",
                  }).format(getTotal()) || 0.0}
                </h1>
                {getTotal() > 0 ? (
                  <p className=" font-normal text-xs leading-3 text-gray">
                    Grundpris
                  </p>
                ) : null}
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </div>
  );
};
export default SummaryThanks;