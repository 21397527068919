import React from "react";

const Button = (props) => {
  const { type, text, disabled } = props;
  return (
    <div>
      <button
        type={type}
        disabled={disabled} // Set the disabled attribute
        className={`py-3 md:py-4 px-4 md:px-11 bg-green rounded-lg font-medium text-sm leading-4 md:leading-6 text-white cursor-pointer ${
          disabled ? "cursor-none opacity-50" : "" // Add cursor-none and reduce opacity for disabled state
        }`}
        {...props}
      >
        {text}
      </button>
    </div>
  );
};

export default Button;
