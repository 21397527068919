import moment from "moment";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";

export const DateCard = ({
  hotelimg,
  day,
  date,
  hide,
  extra_night_cost,
  city,
  dayoption,
  value,
  onChange,
  count = 0
}) => {
  const [nights, setNights] = useState([]);
  useEffect(() => {
    if (value) {
      let arr = [];
      for (let i = 1; i <= Number(value); i++) {
        arr.push(i);
      }
      setNights(arr);
    }
  }, [value]);
  return (
    <div className="pt-4 px-2 md:px-4 md:py-2">
      <div className=" justify-between md:grid md:grid-cols-3  pb-1 pl-5 md:pl-0">
        {/* first */}
        <div>
          <div className="flex-row flex  items-center md:col-span-1">
            <img src={hotelimg} alt="img" className="w-24 h-14 rounded-lg" />
            <div className="ml-4 pb-1 items-start md:ml-4 md:pb-4 md:py-0">
              <h2 className="p-0 m-0 text-base font-medium text-black">
                Dag {day === 1 ? day : (Number(day) + Number(count))}
                <label
                className={` leading-6 text-black text-sm md:text-base xl:text-base font-medium ml-[.5rem] md:hidden`}
              >
              :  {city}
              </label>
              </h2>
              <p className="p-0 m-0 font-normal text-sm leading-5">{moment(date).format('YYYY-MM-DD')}</p>
            </div>
          </div>
        </div>
        {/* secnd */}
        <div className="flex items-center col-span-2 justify-start md:justify-end pt-1">
          <div className=" md:mt-[-20px]" >
            <div className="w-[300px] mb-2 md:mb-0 ">
              <label
                className={` leading-6 text-black text-sm md:text-base xl:text-base font-medium hidden md:block`}
              >
                {city}
              </label>
            </div>
            <div className="flex items-center flex-row md:items-center gap-2 pb-3 md:pb-0">
              {
                hide === true ?
                  ""
                  :
                  <select
                    style={{ border: "1px solid #E7E8E5" }}
                    className={` text-gray text-base font-medium w-16 h-8 bg-white  p-1 border-none rounded `}
                    onChange={(e) => onChange(e.target.value)}
                    value={value}
                  >
                    
                    {dayoption.map((item) => {
                      return (
                        <option
                          id={item.value}
                          key={item.value}
                          className="text-gray font-medium leading-6 text-base"
                          value={item.value}
                        >
                          {item.label}
                        </option>
                      );
                    })}
                  </select>
              }
              {
                hide === true ?
                  ""
                  :

                  <p className="text-gray font-normal	text-sm	leading-5 items-center">
                    {extra_night_cost == "CONTACT_US" ? "Extranatt (vi återkommer med pris)" : `Extranatt från ${new Intl.NumberFormat('sv', { minimumFractionDigits: 0,  maximumFractionDigits: 0, style: 'currency', currency: 'SEK' }).format(extra_night_cost)} / person`}
                  </p>
              }
            </div>
          </div>
        </div>
        {value ? (
          <div className="flex col-span-2 gap-2 flex-wrap ">
            {nights.map((i) => (
              <div className="flex flex-col gap-2 justify-center items-center min-w-[50px] pt-2 ">
                <img
                  src={hotelimg}
                  alt="img"
                  className="w-10 h-7 rounded-lg"
                />
                <small className="text-xs">Dag {Number(day) + Number(count) + Number(i)}</small>
              </div>
            ))}
          </div>
        ) : null}
      </div>

      <hr className="text-gray100 w-[97%] mx-auto mt-4" />
    </div>
  );
};
export default DateCard;