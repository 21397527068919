import React from 'react';
import ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { CaptureConsole as CaptureConsoleIntegration } from "@sentry/integrations";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom';
import { GTMProvider } from '@elgorditosalsero/react-gtm-hook'


Sentry.init({
  dsn: "https://3cc8c718293ff4928681ebde6a1c15c4@o184363.ingest.sentry.io/4505674723098624",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["*"],
      tracingOrigins: ["*"]
    }),
    new Sentry.Replay(),
    new CaptureConsoleIntegration(
      {
        // array of methods that should be captured
        // defaults to ['log', 'info', 'warn', 'error', 'debug', 'assert']
        levels:['log', 'info', 'warn', 'error', 'debug', 'assert']
      }
    )
  ],
  // Performance Monitoring
  tracesSampleRate: 1, // Capture 100% of the transactions, reduce in production!
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const gtmParams = { id: 'GTM-NW8Z55P' }

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
    <GTMProvider state={gtmParams}>
      <App />
    </GTMProvider>
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
