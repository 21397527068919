import React from "react";
import "./index.css"
import { Field, ErrorMessage } from "formik";
import TextError from "../TextError";
function RadioButton(props) {
  const { customClassName, name, options, heading, onChange } = props
  return (
    <>
      {heading ? <p className=' font-normal text-base leading-6 text-gray '>{heading}</p> : null}
      <div className={`${customClassName} flex items-center`}>
        <div className={`flex items-center`}>
          {options.map((option) => {
            return (
              <>
                <div className="mr-5" key={option.value}>
                  <Field name={name} value={option.value} id={option.value}  type="radio"/>
                  <label className="font-normal text-base leading-6 text-gray pl-3" htmlFor={option.value}>{option.label}</label>
                </div>
              </>
            )
          })}
          <ErrorMessage name={name} component={TextError} />
        </div>
      </div>
    </>
  );
}
export default RadioButton;