import React from 'react';
import { ErrorMessage, Field } from 'formik';
import TextError from '../TextError';
const Input = (props) => {
  const { label, name } = props
  return (
    <div className="flex flex-col">
      <label className="mb-1 text-base leading-6 font-normal text-gray" htmlFor={name}>
        {label}
      </label>
      <Field
        {...props}
        className="h-[56px] rounded-lg	p-[12px 16px] bg-white"
        style={{ border: "1px solid #E7E8E5" }}
      />
      <ErrorMessage name={name} component={TextError}/>
    </div>
  );
};

export default Input;