import React from 'react'
import "./index.css"
import { Field, ErrorMessage } from "formik";
import Tooltip from "../Tooltip";
import TextError from '../TextError';
const CheckBox = (props) => {
  const { label, options, text, customClassName, labelcustomClass, tooltip} = props;

  return (
    <div className="m-0 p-0">
      {text ? <p className=' pt-4 pb-[14px] font-normal leading-6 text-base text-gray'>{text}</p> : null}
      <div className={`pb-[14px] ${customClassName}`}>
        <label htmlFor="checkbox">{label}</label>
        {options && options.map((option) => {
          return (
            <div className={`pb-3 ${labelcustomClass}`} key={option.name}>
              <Field  {...props} type="checkbox" name={option.name} id={option.name}></Field>
              <label className='ml-2 font-normal text-base leading-6 text-gray' htmlFor={option.name}>{option.label}{option.price ? <span className='font-medium text-base font text-black'> {option.price}kr</span> : null}</label>
              <Tooltip message={option.tooltip} />
              {ErrorMessage ? <ErrorMessage name={option.name} component={TextError} /> : null}
            </div>
          )
        })}
      </div>

    </div>

  )
}

export default CheckBox
