import { Field, ErrorMessage } from 'formik'
import React from 'react'
import TextError from '../TextError'
const TextArea = (props) => {
  const { label, name } = props
  return (

    <div >
      <label
        htmlFor={name}
        className="block mb-1 text-gray text-sm leading-6 font-normal"
      >
        {label}
      </label>
      <Field
        as='textarea'
        {...props}
        rows="4"
        className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border  focus:ring-blue-500  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 border-none outline-none"
        style={{ border: "1px solid #E7E8E5" }}
      >
      </Field>
      <ErrorMessage name={name} component={TextError} />
    </div>

  )
}

export default TextArea