import { Routes, Route } from 'react-router-dom';
import React from 'react'
import './index.css';
import FormikContainer from './FormikContainer/Index';
import Thanks from './Thanks/Index';

const App = () => {
  return (
 <>
  <Routes>
    <Route path="/:slug" element={<FormikContainer />} />
    <Route path="/tack/:uuid" element={<Thanks />} />
  </Routes>
 </>
  )
}

export default App
