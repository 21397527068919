import React, { useState } from 'react';
import { FaInfoCircle } from 'react-icons/fa'; // Correct import for the info icon

export default function Tooltip({ message}) {
    const [showTooltip, setShowTooltip] = useState(false);

    if (!message) {
        return null;
    }

    return (
        <div className="inline-block ml-2" style={{ flexDirection: 'column', alignItems: 'center' }}>
            <div onClick={() => setShowTooltip(!showTooltip)}>
                <FaInfoCircle />
            </div>
            {showTooltip && <span className="absolute inline-block transition-all rounded bg-black p-2 text-xs text-white">{message}</span>}
        </div>
    )
}