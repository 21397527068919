import React, { Fragment, useEffect, useRef, useState } from "react";
import Avatar from "../components/Avatar";
import { travl, voctor, contact, extra, doller, arrow } from "../assets";
import { downarrow } from "../assets"; //arrow,
import Heading from "../components/Heading";
import Button from "../components/Button";
import DateCard from "../components/DateCard";
import Summary from "../components/Summary";
import * as Yup from "yup";
import { Formik, Form, FieldArray } from "formik";
import FormControl from "../FormikControl";
import { useParams, useNavigate } from "react-router-dom";

import Calander from "../components/Calander";
import MbleSummary from "../components/MobileSummery";
import moment from "moment";
import SmallScreenClnder from "../components/MoblieCalander";
import FormObserver from "./FormObserver";
import { Dialog, Transition } from "@headlessui/react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";

const dayoption = [
  {
    id: "0",
    label: "0",
  },
  {
    id: "01",
    label: "1",
  },
  {
    id: "02",
    label: "2",
  },
  {
    id: "03",
    label: "3",
  },
];

const FormikContainer = () => {
  let apiUrl;
  if (process.env.NODE_ENV === "development") {
    apiUrl = "http://localhost:9000";
  } else {
    apiUrl = "https://www.travl.se";
  }

  const navigate = useNavigate();

  const { slug } = useParams();

  const listAdults = [
    { key: "1", name: "1", value: "1" },
    { key: "2", name: "2 ", value: "2" },
    { key: "3", name: "3 ", value: "3" },
    { key: "4", name: "4 ", value: "4" },
    { key: "5", name: "5 ", value: "5" },
    { key: "6", name: "6 ", value: "6" },
    { key: "7", name: "7 ", value: "7" },
    { key: "8", name: "8 ", value: "8" },
    { key: "9", name: "9 ", value: "9" },
    { key: "10", name: "10 ", value: "10" },
    { key: "11", name: "11 ", value: "11" },
    { key: "12", name: "12 ", value: "12" },
  ];

  // gender
  const gender = [
    { label: "Man", value: "MALE" },
    { label: "Kvinna", value: "FEMALE" },
  ];

  const listOfFights = [
    { label: "Ja", value: "true" },
    { label: "Nej", value: "false" },
  ];
  const Flexibility = [
    { label: "Ja +/- 3 dagar", value: "true" },
    { label: "Nej", value: "false" },
  ];
  const insurance = [
    {
      label: "Reseförsäkring via ERV (vi återkommer med pris)",
      name: "trip_insurance",
      value: 0,
    },
    {
      label: "Avbeställningsskydd med ERV (7% av resans pris)",
      name: "cancel_insurance",
      value: 0,
    },
  ];
  const airports = [
    { name: "ARN", value: "ARN", label: "Stockholm" },
    { name: "CPH", value: "CPH", label: "Köpenhamn" },
    { name: "OSL", value: "OSL ", label: "Oslo" },
    { name: "GOT", value: "GOT", label: "Göteborg" },
  ];
  const confirmbooking = [
    {
      label: "Jag har läst & godkänner resevillkoren",
      name: "confirmbooking",
      value: "yes",
    },
  ];
  const [open, setOpen] = useState(false);
  const [modal, setModal] = useState(false);
  const cancelButtonRef = useRef(null);
  const [selectedTriplength, setSelectedTriplength] = useState("");
  const [firstDate, setFirstDate] = useState("");
  const [lastDate, setLastDate] = useState("");
  const [product, setProduct] = useState("");
  const [departureData, setDepartureData] = useState([]);
  const [date, setDate] = useState(new Date().toISOString().slice(0, 10));
  const [summary, setSummary] = useState({});
  const [passengersLoading, setPassengersLoading] = useState(false);
  const [waitlist, setWaitlist] = useState(true);
  const [email, setEmail] = useState("");
  const [isOpen, setIsOpen] = useState(true);
  const [error, setError] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [wholeAmount, setWholeAmount] = useState();
  const initialValues = {
    gender: "male",
    firstName: "",
    surname: "",
    email: "",
    phone: "",
    street: "",
    postcode: "",
    noOfAdults: "1",
    city: "",
    age: "",
    height: "",
    confirmbooking: "",
    typeOfRoom: "",
  };

  const formikRef = useRef(null);

  const validationSchema = Yup.object({
    email: Yup.string().required("Obligatoriskt"),
    firstName: Yup.string().required("Obligatoriskt"),
    surname: Yup.string().required("Obligatoriskt"),
    phone: Yup.string().required("Obligatoriskt"),
    street: Yup.string().required("Obligatoriskt"),
    postcode: Yup.string().required("Obligatoriskt"),
    city: Yup.string().required("Obligatoriskt"),
    age: Yup.string().required("Obligatoriskt"),

    height: Yup.string().required("Obligatoriskt"),
    confirmbooking: Yup.string().required("Obligatoriskt"),
    typeOfRoom: Yup.string().required("Obligatoriskt"),
    passengers: Yup.array().of(
      Yup.object().shape({
        firstName: Yup.string().required("Obligatoriskt"),
        surname: Yup.string().required("Obligatoriskt"),
        age: Yup.string().required("Obligatoriskt"),

        height: Yup.string().required("Obligatoriskt"),
        typeOfRoom: Yup.string().required("Obligatoriskt"),
      })
    ),
  });

  const scrollToError = () => {
    const errorElement = document.querySelector(".error");
    if (errorElement) {
      errorElement.scrollIntoView({ behavior: "smooth", block: "start" });
      setError(true);
    } else {
      setError(false);
    }
  };

  useEffect(() => {
    fetchProductDetails();
  }, []);

  useEffect(() => {
    if (date && selectedTriplength) {
      fetchProductPrices();
    }
  }, [date, selectedTriplength]);

  const handleSubmitEmail = async (event) => {
    event.preventDefault();

    // URL endpoint where you want to send the POST request
    const url = `${apiUrl}/api/save-alert`;

    try {
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email: email, slug: slug }),
      });

      if (response.ok) {
        setEmail("");
        alert("Tack! Vi återkommer inom kort");
      } else {
        // Handle errors or unsuccessful responses
        console.error("Could not save TRAVL Alert", response);
        alert("Tack! Vi återkommer inom kort");
      }
    } catch (error) {
      console.error("Could not save TRAVL alert", error);
      alert("Tack! Vi återkommer inom kort");
    }
  };

  const handleSubmit = (values, onsubmitprops) => {
    debugger;
    setBtnDisabled(true);
    fetch(`${apiUrl}/booking-ajax`, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...values,
        trip_itinerary: departureData?.trip_itinerary,
        product_option_id: selectedTriplength?.id,
        options: departureData?.extras?.map((e) => {
          const keyName = e.name.replaceAll(/\s+/g, "");
          if (Object.keys(values).includes(keyName)) {
            return {
              ...e,
              selected: values[keyName],
            };
          } else return e;
        }),
        start_date: moment(date).format("YYYY-MM-DD"),
        end_date: moment(getEndDate).format("YYYY-MM-DD"),
      }),
    })
      .then((response) => {
        // This returns a Promise
        return response.json();
      })
      .then((resp) => {
        if (resp.success) {
          const uuid = resp.uuid;
          navigate(`/tack/${uuid}`);
        } else if (resp.trustly) {
          window.location.replace(resp.redirect_url);
        } else {
          console.error("Could not understand response from server : " + resp);
          setOpen(true);
          setBtnDisabled(false);
        }
      })
      .catch((error) => {
        console.error(error, "error");
        setOpen(true);
        setBtnDisabled(false);
      });

    {
      /* onsubmitprops.resetForm(); */
    }
  };

  const handleDateChange = (date, _, __) => {
    setDate(date);
  };

  const handleAdultsChange = (e, values, setValues) => {
    setPassengersLoading(true);
    const value = e.target.value;
    const total = Number(value) || 0;
    const passengers = Number(values.passengers) || [];

    for (let i = 1; i < value; i++) {
      passengers.push(
        {
          id: value,
          firstName: "",
          surname: "",
          age: null,
          gender: "male",
          typeOfRoom: null,
          listOfbikes: null,
          height: null,
        },
        true
      );
    }
    setValues({
      ...values,
      noOfAdults: value,
      passengers: passengers.filter((p) => p.id),
    });
    setTimeout(() => {
      setPassengersLoading(false);
    }, 100);
  };

  const handletriplengthChange = (e, values, setValues) => {
    let triplength = Number(e.target.value);
    setValues({ ...values, triplength });
    setSelectedTriplength(findIdById(triplength));
  };

  const findIdById = (id) => {
    const item = product.trip_lengths.find((item) => item.id == id);
    return item ? item : [];
  };

  const handleItineraryChange = (value, item, index) => {
    let updated = departureData?.trip_itinerary?.map((ti) => {
      if (item.day === ti.day)
        return {
          ...ti,
          value,
        };
      else return ti;
    });

    updated = updated?.map((ti, i) => {
      if (i > index) {
        let count = 0;
        const arr = [...updated].splice(0, i);
        arr.forEach((a) => {
          count += Number(a.value);
        });
        return {
          ...ti,
          count,
          date: moment(ti.temp_date).add(count, "days").format("YYYY-MM-DD"),
        };
      } else return ti;
    });

    setDepartureData({
      ...departureData,
      trip_itinerary: updated,
    });
  };

  const fetchProductPrices = async () => {
    try {
      const response = await fetch(
        `${apiUrl}/api/get-product-prices?prod_length=${
          selectedTriplength?.id
        }&date=${moment(date).format("YYYY-MM-DD")}`
      );

      const data = await response.json();
      if (data.no_dates) {
        setModal(true);
      } else if (data.dateChanged) {
       // select new date on calendar
       setDate(data.newDate);
      } else {
        setDepartureData({
          ...data,
          trip_itinerary: data.trip_itinerary?.map((a) => {
            return {
              ...a,
              temp_date: a.date,
              value: 0,
            };
          }),
        });
  
        formikRef?.current?.setValues({
          ...formikRef?.current?.values,
          // typeOfRoom: findCheapestRoom(data?.rooms, "price")?.id,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  // State for the background image
  const [backgroundImage, setBackgroundImage] = useState("");

  // State for the cykling or not
  const [isBike, setIsBike] = useState("");

  const fetchProductDetails = async () => {
    try {
      const response = await fetch(
        `${apiUrl}/api/get-product-details?prod_slug=${slug}`
      );
      const data = await response.json();
      setProduct(data);
      setSelectedTriplength(data?.trip_lengths?.[0]);
      setDate(data?.trip_lengths?.[0]?.available_dates?.[0]);
      setBackgroundImage(data.img);
      setIsBike(data.isBike);
      setFirstDate(data?.first_date);
      setLastDate(data?.last_date);
      if (data.no_dates) {
        setModal(true);
      }
    } catch (error) {
      console.error(error);
      setModal(true);
     // window.location.replace("https://www.travl.se");
    }
  };

  const firstOptionNull = (array = []) => {
    return [{ price: 0, label: "None", name: "Inga" }, ...array];
  };

  const findCheapestRoom = (a, f) =>
    a.length ? a.reduce((m, x) => (m[f] < x[f] ? m : x)) : undefined;

  const findPrice = (id, array) => {
    if (!array || !id) return { price: 0 };
    return array.find((b) => b.id == id);
  };

  const handleFormChange = () => {
    const values = formikRef?.current?.values;
    let listOfbikes = 0;
    let amountOfBikes = 0;

    let listOfRooms = 0;
    let amountOfRooms = 0;

    let totalAdults = 1;

    let totalExtraNights = 0;
    let amountOfExtraNights = 0;

    if (values?.listOfbikes) {
      listOfbikes = 1;
      if (values.listOfbikes === "Inga") {
        amountOfBikes += 0;
      } else {
        amountOfBikes = findPrice(
          values?.listOfbikes,
          departureData?.bike_hire
        )?.price;
      }
    }

    if (values?.typeOfRoom) {
      listOfRooms = 1;
      if (values.typeOfRoom === "Inga") {
        amountOfRooms = 0;
      } else {
        amountOfRooms = findPrice(
          values?.typeOfRoom,
          departureData?.rooms
        )?.price;
      }
    }

    if (values?.passengers?.length) {
      values.passengers?.forEach((p) => {
        if (p.listOfbikes) {
          listOfbikes += 1;
          if (p.listOfbikes === "Inga") {
            amountOfBikes += 0;
          } else {
            amountOfBikes += findPrice(
              p.listOfbikes,
              departureData?.bike_hire
            )?.price;
          }
        }

        if (p.typeOfRoom) {
          listOfRooms += 1;
          if (p.typeOfRoom === "Inga") {
            amountOfRooms += 0;
          } else {
            amountOfRooms += findPrice(
              p.typeOfRoom,
              departureData?.rooms
            )?.price;
          }
        }
      });
    }

    const formValues = formikRef?.current?.values || {};
    let numberofAdult = formValues.noOfAdults;

    if (departureData?.trip_itinerary?.length) {
      departureData.trip_itinerary.forEach((t) => {
        if (t.value) {
          totalExtraNights += Number(t.value);
          if (typeof t.extra_night_cost === "number")
            amountOfExtraNights +=
              t.extra_night_cost * Number(t.value) * Number(numberofAdult);
        }
      });
    }
    setSummary({
      listOfbikes,
      amountOfBikes,
      listOfRooms,
      amountOfRooms,
      totalAdults,
      totalExtraNights,
      amountOfExtraNights,
    });
  };

  const {
    listOfbikes,
    amountOfBikes,
    listOfRooms,
    amountOfRooms,
    totalAdults,
    totalExtraNights,
    amountOfExtraNights,
  } = summary;
  const getTotalAmount = () =>
    amountOfBikes + amountOfRooms + amountOfExtraNights;
  const getTotal = () => {
    let partialprice = Number(
      formikRef?.current?.values.insurance_partial ? 20 : 0
    );
    let completeprice = Number(
      formikRef?.current?.values.insurance_complete ? 40 : 0
    );
    let wholeAmount = partialprice + completeprice;

    let extrasAmount = 0;

    const formValues = formikRef?.current?.values || {};

    Object.keys(formValues).forEach((key) => {
      departureData.extras?.forEach((e) => {
        if (
          formValues[key] &&
          e.name.replaceAll(/\s+/g, "") === key &&
          e.price
        ) {
          if (e.pricing === "per_person") {
            extrasAmount += Number(e.price) * Number(formValues.noOfAdults);
          } else {
            extrasAmount += Number(e.price);
          }
        }
      });
    });
    setWholeAmount(extrasAmount + wholeAmount);
    return getTotalAmount() * totalAdults + wholeAmount + extrasAmount;
  };
  const getEndDate = () => {
    const lastItem =
      departureData?.trip_itinerary?.[
        departureData?.trip_itinerary?.length - 1
      ];
    const date = lastItem?.date;
    return moment(date).add(lastItem?.value, "days");
  };

  /*
  let slugish = "" ;
  if (window.location.pathname?.split("/") !== undefined && window.location.pathname?.split("/").length > 0) {
    slugish = window.location.pathname?.split("/")[1];
  }

  if (slugish === "") window.location.replace('https://www.travl.se');
  */

  return (
    <>
      <main className="bg-white">
        <div className="mx-auto ">
          <div className="xl:grid grid-cols-12">
            <div
              className=" xl:col-span-8 w-full xl:w-[46rem] mx-auto justify-center
                    xl:justify-items-start "
            >
              <nav className="ml-4 px-0 md:px-3 xl:px-0 lg:ml-0 pb-6 pt-6">
                <a href="https://www.travl.se">
                  <img src={travl} alt="TRAVL.se" />
                </a>
                <h1 className="font-bold  text-[21px] md:text-[32px] leading-[38.4px] text-black pb-1 pt-5 xl:pt-12 font">
                  {product?.title}
                </h1>
                <p className="font-normal text-base  text-gray">
                  Bokningsförmulär
                </p>
              </nav>
              <div className="bg-gray200 rounded-2xl rounded-b-none px-3 xl:p-10 xl:pb-0">
                {/* ===========Choose your trip avatar ========== */}
                <div className="">
                  <Avatar logo={voctor} heading="Välj din resa" />
                </div>

                {/* ========Triplength input===== */}
                <div className="pt-6">
                  <div>
                    <label
                      htmlFor={"triplength"}
                      className={` leading-6 text-gray text-base`}
                    >
                      Välj bland följande{" "}
                    </label>
                    <select
                      style={{ border: "1px solid #E7E8E5" }}
                      id={"product_option"}
                      className={`text-gray text-base font-medium leading-6 h-[56px] bg-white w-full p-[12px 16px] border-none rounded-lg mt-1 relative selectSearch`}
                      onChange={(e) => {
                        handletriplengthChange(
                          e,
                          formikRef?.current?.values,
                          formikRef?.current?.setValues
                        );
                      }}
                    >
                      {product?.trip_lengths &&
                        product?.trip_lengths.map((option) => {
                          return (
                            <option
                              id={option.id}
                              className="option"
                              value={option.id}
                            >
                              <div className=" font-medium leading-6 text-base border-none outline-none">
                                {option.name}
                              </div>
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
                {/* ========Select a departure date below and Select a departure date below======== */}
                <div className="pt-8">
                  <div className="pb-4">
                    <h1 className="text-black leading-7 text-lg font-bold tracking-[0.01em] pb-1">
                      När vill du resa?
                    </h1>
                    <p className="text-gray text-sm leading-5">
                      Välj ankomstdatum
                    </p>
                  </div>
                </div>
                {/* =========calander============== */}

                <div className="hidden xl:block bg-white rounded-2xl border-none">
                  <Calander
                    product={product}
                    selectedTriplength={selectedTriplength}
                    onChange={handleDateChange}
                    minDate={firstDate}
                    maxDate={lastDate}
                    value={date}
                    prev2Label={null}
                    next2Label={null}
                  />
                </div>
                {/* ==============mobilescreen========== */}
                <div className="block xl:hidden bg-white rounded-2xl border-none">
                  <SmallScreenClnder
                    product={product}
                    selectedTriplength={selectedTriplength}
                    onChange={handleDateChange}
                    value={date}
                    prev2Label={null}
                    next2Label={null}
                  />
                </div>
              </div>

              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                innerRef={formikRef}
                enableReinitialize={true}
              >
                {(formik) => {
                  return (
                    <Form>
                      <FormObserver
                        handleFormChange={handleFormChange}
                        departureData={departureData}
                      />
                      <section className="bg-gray200 rounded-2xl rounded-t-none">
                        <div className="px-3 xl:p-10 xl:pt-0">
                          {/* =====================Trip itinerary =================*/}
                          <div className="pt-8">
                            <div className="pb-4">
                              <h1 className="text-black leading-7 text-lg font-bold pb-2">
                                Dag för dag program
                              </h1>
                              <p className="text-gray text-sm leading-5 pb-2">
                                Extranätter? Vi återkommer med exaktpris innan
                                vi bekräftar din resa.
                              </p>
                              <div className=" bg-white rounded-2xl pt-2">
                                {departureData?.trip_itinerary?.length ? (
                                  departureData?.trip_itinerary?.map(
                                    (tripitem, index) => {
                                      const {
                                        city,
                                        day,
                                        label,
                                        pic_url,
                                        date,
                                        value,
                                        extra_night_cost,
                                        hide,
                                        count,
                                      } = tripitem;
                                      return (
                                        <div className="max-w-720px">
                                          <DateCard
                                            value={value}
                                            hotelimg={pic_url}
                                            hide={hide}
                                            day={day}
                                            date={date}
                                            extraday={label}
                                            city={city}
                                            count={count}
                                            extra_night_cost={extra_night_cost}
                                            dayoption={dayoption}
                                            onChange={(value) =>
                                              handleItineraryChange(
                                                value,
                                                tripitem,
                                                index
                                              )
                                            }
                                          />
                                        </div>
                                      );
                                    }
                                  )
                                ) : (
                                  <p className="p-3 text-gray">
                                    Ingen dag för dag program
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                      {/* =============Who is travelling? forms=============- */}
                      <section className="mt-6">
                        <div className="bg-gray200 px-4 pb-4 xl:p-10 rounded-2xl">
                          <div className="pb-6">
                            <Avatar logo={contact} heading="Resenärer" />
                          </div>
                          {/* 1st */}
                          <div className="grid grid-cols-2 gap-5">
                            <div className="col-span-2 md:col-span-2">
                              <FormControl
                                control="select"
                                label="Antal resenärer"
                                name="noOfAdults"
                                options={listAdults}
                                onChange={(e) =>
                                  handleAdultsChange(
                                    e,
                                    formik.values,
                                    formik.setValues
                                  )
                                }
                                customClassName="text-gray text-base font-medium leading-6"
                              />
                            </div>
                          </div>
                          <p className="pt-8 pb-4 font-bold text-lg text-darkblack leading-7">
                            Beställaren / Resenär 1
                          </p>
                          <div className=" grid grid-cols-2 gap-5">
                            <div className="col-span-2 md:col-span-1">
                              <FormControl
                                control="input"
                                type="text"
                                label="Förnamn"
                                name="firstName"
                              />
                            </div>
                            <div className="col-span-2 md:col-span-1">
                              <FormControl
                                control="input"
                                type="text"
                                label="Efternamn"
                                name="surname"
                              />
                            </div>
                            <div className="col-span-2 md:col-span-1">
                              <FormControl
                                control="input"
                                type="text"
                                label="E-postadress"
                                name="email"
                              />
                            </div>
                            <div className="col-span-2 md:col-span-1">
                              <FormControl
                                control="input"
                                type="text"
                                label="Mobilnummer"
                                name="phone"
                              />
                            </div>
                          </div>
                          {/* secound */}
                          <div className="grid grid-cols-3 gap-5 pt-4">
                            <div className="col-span-3 md:col-span-1">
                              <FormControl
                                control="input"
                                type="text"
                                label="Adress"
                                name="street"
                              />
                            </div>
                            <div className="col-span-3 md:col-span-1">
                              <FormControl
                                control="input"
                                type="text"
                                label="Postnummer"
                                name="postcode"
                              />
                            </div>
                            <div className="col-span-3 md:col-span-1">
                              <FormControl
                                control="input"
                                type="text"
                                label="Ort"
                                name="city"
                              />
                            </div>
                          </div>
                          {/* 3rd */}
                          <div className="grid grid-cols-3 gap-5 pt-4">
                            <div className="col-span-3 md:col-span-1">
                              <FormControl
                                control="input"
                                type="number"
                                label="Ålder"
                                name="age"
                              />
                            </div>
                            <div className=" col-span-3 md:col-span-1">
                              <FormControl
                                control="input"
                                type="number"
                                label="Längd i cm"
                                name="height"
                              />
                            </div>
                            <div className="col-span-3 md:col-span-1">
                              <p className="font-normal text-base leading-6 text-gray">
                                Kön
                              </p>

                              <FormControl
                                control="radio"
                                name="gender"
                                options={gender}
                                customClassName="mt-5"
                              />
                            </div>
                          </div>
                          {/* 4th */}
                          <div className="grid grid-cols-2 gap-5 pt-4">
                            <div className="col-span-2 md:col-span-1">
                              <FormControl
                                control="select"
                                label="Välj rum"
                                name="typeOfRoom"
                                options={firstOptionNull(departureData?.rooms)}
                                customClassName="text-gray text-base font-medium leading-6"
                              />
                            </div>
                            {isBike && (
                              <div className="col-span-2 md:col-span-1">
                                <FormControl
                                  control="select"
                                  label="Cykelhyra?"
                                  name="listOfbikes"
                                  options={firstOptionNull(
                                    departureData?.bike_hire
                                  )}
                                  customClassName="text-gray text-base font-medium leading-6"
                                />
                              </div>
                            )}
                          </div>
                          {!passengersLoading ? (
                            <FieldArray name="passengers">
                              {() =>
                                formik.values?.passengers?.map(
                                  (passenger, i) => {
                                    return (
                                      <div>
                                        <p className="pt-8 pb-4 font-bold text-lg text-darkblack leading-7">
                                          Resenär {i + 2}
                                        </p>
                                        <div className=" grid grid-cols-2 gap-5">
                                          <div className="col-span-2 md:col-span-1">
                                            <FormControl
                                              control="input"
                                              type="text"
                                              label="Förnamn"
                                              name={`passengers.${i}.firstName`}
                                            />
                                          </div>
                                          <div className="col-span-2 md:col-span-1">
                                            <FormControl
                                              control="input"
                                              type="text"
                                              label="Efternamn"
                                              name={`passengers.${i}.surname`}
                                            />
                                          </div>
                                        </div>
                                        {/* 3rd */}
                                        <div className=" grid grid-cols-3 gap-5 pt-4">
                                          <div className="col-span-3 md:col-span-1">
                                            <FormControl
                                              control="input"
                                              type="number"
                                              label="Ålder vid avresa"
                                              name={`passengers.${i}.age`}
                                            />
                                          </div>
                                          <div className=" col-span-3 md:col-span-1">
                                            <FormControl
                                              control="input"
                                              type="number"
                                              label="Längd i cm"
                                              name={`passengers.${i}.height`}
                                            />
                                          </div>
                                          <div className="col-span-3 md:col-span-1">
                                            <p className="font-normal text-base leading-6 text-gray">
                                              Kön
                                            </p>
                                            <FormControl
                                              control="radio"
                                              name={`passengers.${i}.gender`}
                                              options={gender}
                                              customClassName="mt-5"
                                            />
                                          </div>
                                        </div>
                                        {/* 4th */}
                                        <div className="grid grid-cols-2 gap-5 pt-4">
                                          <div className="col-span-2 md:col-span-1">
                                            <FormControl
                                              control="select"
                                              label="Välj rum"
                                              value={passenger.typeOfRoom}
                                              name={`passengers.${i}.typeOfRoom`}
                                              options={firstOptionNull(
                                                departureData?.rooms
                                              )}
                                              customClassName="text-gray text-base font-medium leading-6"
                                            />
                                          </div>
                                          {isBike && (
                                            <div className="col-span-2 md:col-span-1">
                                              <FormControl
                                                control="select"
                                                label="Cykelhyra?"
                                                value={passenger.listOfbikes}
                                                name={`passengers.${i}.listOfbikes`}
                                                options={firstOptionNull(
                                                  departureData?.bike_hire
                                                )}
                                                customClassName="text-gray text-base font-medium leading-6"
                                              />
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    );
                                  }
                                )
                              }
                            </FieldArray>
                          ) : null}
                        </div>
                      </section>
                      {/* ================Extras section============ */}
                      <section className="mt-6">
                        <div className="bg-gray200 px-6 pb-4 xl:p-10 rounded-2xl">
                          <div>
                            <Avatar logo={extra} heading="Tillval & önskemål" />
                            {/* FLYG
                            <p className="pt-8 pb-4 font-bold text-lg text-darkblack leading-7">
                              Flyg
                            </p>
                            <Heading Description="We are here to help you with any questions or concerns you may have regarding flights. Whether you're looking for information on flight schedules, booking options." />
                            <div className="pb-2"></div>
                            <div>
                              <FormControl
                                control="radio"
                                name="flight_help"
                                options={listOfFights}
                                customClassName="pt-2 pb-3 "
                                heading="Behöver du hjälp med flyg?"
                              />
                              <div>
                                {formik.values.flight_help ===
                                listOfFights[0]?.value ? (
                                  <div className="">
                                    <small className=" font-normal text-base leading-6 text-gray">
                                      Från vilken flygplats?
                                    </small>
                                    <div className="flex">
                                      <FormControl
                                        control="checkbox"
                                        options={airports}
                                        labelcustomClass={`${
                                          formik.values.flight_help ===
                                          listOfFights[0]?.value
                                            ? "pr-0 md:pr-4 "
                                            : ""
                                        }`}
                                        customClassName={`${
                                          formik.values.flight_help ===
                                          listOfFights[0]?.value
                                            ? " md:flex-column md:flex justify-around items-center"
                                            : null
                                        }`}
                                        onChange={formik.handleChange}
                                      />
                                    </div>
                                  </div>
                                ) : null}
                              </div>
                            </div>
                            <div className="pt-3">
                              <FormControl
                                control="radio"
                                name="extraflight"
                                options={extraflight}
                                customClassName="pt-2 "
                                heading="Have you already looked at flights?"
                                      /> 
                            </div> */}
                          </div>

                          <div className="pt-4 pb-4">
                            <p className=" font-bold text-lg text-darkblack leading-7 pb-2">
                              Tillval / Önskemål
                            </p>
                            <FormControl
                              control="checkbox"
                              options={departureData?.extras?.map((d) => {
                                return {
                                  ...d,
                                  name: d.name.replaceAll(/\s+/g, ""),
                                  label: d.name,
                                  value: d.price,
                                  tooltip: d.tooltip,
                                };
                              })}
                              onChange={formik.handleChange}
                              text=""
                              // Transferprice={}
                            />

                            <div className="">
                              <Heading Description="Nedan kan du skriva övriga önskemål kring din bokning" />
                            </div>
                          </div>
                          <FormControl
                            control="textarea"
                            label="Önskemål"
                            name="comment"
                          />

                          <div className="pt-8">
                            <p className="pb-4 font-bold text-lg text-darkblack leading-7">
                              Är du flexibel med startdatum för resan?
                            </p>
                            <FormControl
                              control="radio"
                              name="flexible_date"
                              options={Flexibility}
                              customClassName="pt-[10px] "
                              heading="Är du flexibel med startdatum för resan?"
                            />
                          </div>
                        </div>
                      </section>
                      {/*======================Insurance and Payment================== */}
                      <section className="mt-6">
                        <div className="bg-gray200 px-6 pb-4 xl:p-10 rounded-2xl">
                          <Avatar
                            logo={doller}
                            heading="Avbeställningskydd & betalning"
                          />

                          <div className="pt-8 ">
                            <div
                              className={`flex justify-between items-center ${
                                isOpen ? "pb-4" : null
                              }`}
                            >
                              <p className=" font-bold text-lg text-darkblack leading-7 ">
                                Avbeställningskydd
                              </p>
                              <div
                                className="transition cursor-pointer w-8 h-8 flex justify-center items-center bg-limeGreen rounded-[40px]"
                                onClick={() => setIsOpen(!isOpen)}
                                style={
                                  isOpen ? { transform: "rotate(180deg)" } : {}
                                }
                              >
                                <img src={downarrow} alt="icon" />
                              </div>
                            </div>
                            {isOpen && (
                              <div className={` rounded-lg p-3 bg-gray100 `}>
                                <p className=" font-normal text-sm leading-6 text-gray">
                                  TRAVL erbjuder försäkringar i samarbete med
                                  ERV.<br></br>- Avbeställningsskydd gäller om
                                  du plötsligt och oväntat inte kan åka på din
                                  resa.
                                  <a
                                    href="https://www.erv.se/privat/vara-reseforsakringar/avbestallningsskydd/"
                                    target="_blank"
                                    className="text-green ml-1"
                                  >
                                    Läs mer
                                  </a>
                                  <br></br>- Reseförsäkring ger dig skydd ifall
                                  du blir akut sjuk eller skadad under resan.
                                  <a
                                    href="https://www.erv.se/privat/vara-reseforsakringar/reseforsakring-bas-och-plus/"
                                    target="_blank"
                                    className="text-green ml-1"
                                  >
                                    Läs mer
                                  </a>
                                </p>
                              </div>
                            )}
                            <FormControl
                              control="checkbox"
                              options={insurance}
                              text="Välj försäkring nedan:"
                            />

                            <div className="pt-4">
                              <Heading
                                titel="Betalning"
                                Description="Anmälningsavgiften är 20 % av resans pris och debiteras vid bekräftad bokning. Avgiften ska vara TRAVL tillhanda senast 7 dagar efter bekräftad bokning. Slutbetalning ska vara TRAVL tillhanda senast 40 dagar innan avresa. Sker bokningen närmare än 40 dagar innan avresa ska betalning ske omgående."
                              />
                            </div>
                          </div>
                        </div>
                      </section>
                      {/* ================for mobile version========================= */}
                      {/* ===========travl + From Portofino to Portovenere + Hiking trips on your own section===== */}
                      <section className=" block xl:hidden  bg-white rounded-2xl">
                        <MbleSummary
                          // isVisible={isVisible}
                          totalExtraNights={totalExtraNights}
                          getTotal={getTotal}
                          getTotalAmount={getTotalAmount}
                          formik={formik}
                          departureData={departureData}
                          included={product?.included}
                          date={date}
                          endDate={getEndDate}
                          validationSchema={validationSchema}
                          confirmBookingOption={confirmbooking}
                        />
                      </section>
                      {/* =========================Confirm==============*/}
                      <section className="mt-6 hidden xl:block">
                        <div className="bg-gray200 p-10 rounded-2xl">
                          <Avatar logo={arrow} heading="Bekräfta" />
                          <div className="py-3"></div>
                          <FormControl
                            control="checkbox"
                            name="confirmbooking"
                            options={confirmbooking}
                          />

                          <div className="pt-[15px]">
                            {validationSchema.length > 0 && (
                              <div className="error text-red-500 mb-2">
                                {validationSchema.map((error, index) => (
                                  <p key={index}>{error}</p>
                                ))}
                              </div>
                            )}
                            {error ? (
                              <p className="error mb-4">
                                OBS Du glömde vissa fält
                              </p>
                            ) : null}
                            <Button
                              text="Slutför bokningen"
                              type="submit"
                              disabled={btnDisabled}
                              onClick={scrollToError}
                            />
                          </div>
                        </div>
                      </section>
                    </Form>
                  );
                }}
              </Formik>
            </div>

            {/* ====================Tour Summary secoutn--------------- */}
            <div className=" col-span-4 w-[300px] max-w-[100%] relative hidden xl:block">
              <div
                className="fixed w-full"
                id="travl"
                style={{ backgroundImage: `url(${backgroundImage})` }}
              >
                <section className="travl_trip max-w-[100%] absolute w-[450px] md:w-[400] xl:w-[450px] 2xl:w-[430px] bg-white rounded-2xl  md:top-[2.6rem] md:right-[10rem] xl:right-[9rem]">
                  <Summary
                    included={product?.included}
                    date={date}
                    endDate={getEndDate}
                    getTotalAmount={getTotalAmount}
                    totalAdults={totalAdults}
                    listOfbikes={listOfbikes}
                    listOfRooms={listOfRooms}
                    totalExtraNights={totalExtraNights}
                    getTotal={getTotal}
                    amountOfBikes={amountOfBikes}
                    amountOfRooms={amountOfRooms}
                    amountOfExtraNights={amountOfExtraNights}
                    wholeAmount={wholeAmount}
                  />
                </section>
              </div>
            </div>
          </div>
        </div>
      </main>

      {/* =================== ERROR MODAL --------------- */}
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray200 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div className="sm:flex sm:items-start">
                      <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                        <ExclamationTriangleIcon
                          className="h-6 w-6 text-red-600"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                        <Dialog.Title
                          as="h3"
                          className="text-base font-semibold leading-6 text-gray-900"
                        >
                          FEL : Vi kunde inte skapa din bokning
                        </Dialog.Title>
                        <div className="mt-2">
                          <p className="text-sm text-gray-500">
                            Vi kunde tyvärr inte skapa din bokning.<br></br>{" "}
                            Vänligen kontakta oss via telefon 031 361 34 28
                            eller mejl info@travl.se
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                    <button
                      type="button"
                      className="bg-red-700 mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={() => setOpen(false)}
                      ref={cancelButtonRef}
                    >
                      Stäng
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      {/* =================== NO DATES MODAL --------------- */}
      <Transition.Root show={modal} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setModal}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray200 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform opacity-100 overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div className="sm:flex sm:items-start">
                      <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                        <ExclamationTriangleIcon
                          className="h-6 w-6 text-red-600"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                        <Dialog.Title
                          as="h3"
                          className="leading-7 text-[1.4rem] md:text-2xl text-green font-bold"
                        >
                          Du är lite tidig...
                        </Dialog.Title>
                        <div className="mt-2">
                          <p className="leading-6 text-gray-500">
                          
I nuläget finns det inga bokningsbara datum för din resa.<br></br>
Fyll gärna i din mailadress nedan så återkommer vi när det är dags att boka.
                          </p>
                          <br></br>
                          <form onSubmit={handleSubmitEmail}>
                            <input
                              type="email"
                              required="required"
                              className="h-[56px] rounded-lg	p-[12px 16px] bg-white w-full"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                              placeholder="Enter your email"
                            />
                            <br></br>
                            <br></br>
                            <button
                              type="submit"
                              className="py-1 md:py-2 px-4 md:px-11 bg-green rounded-lg font-medium text-sm leading-4 md:leading-6 text-white cursor-pointer "
                            >
                              Spara
                            </button>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default FormikContainer;
