import React from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css'
import "./index.css"
import moment from 'moment/moment';
const SmallScreenClnder = ({ onChange, value, prev2Label, next2Label, tileClassName, calendarType, maxDetail, showNeighboringMonth, selectedTriplength, showNavigation }) => {

    const handleDateDisable = (date) => {
        return !(selectedTriplength?.available_dates?.find(d => moment(date).isSame(moment(d))))
    }

    return (
        <div>
            {selectedTriplength && <Calendar
                onClickDay={onChange}
                value={value}
                prev2Label={null}   
                next2Label={null}
                tileClassName={tileClassName}
                showNavigation={true}
                calendarType={"US"}
                navigationAriaLive="off"
                showNeighboringMonth={false}
                showFixedNumberOfWeeks={false}
                showWeekNumbers={false}
                goToRangeStartOnSelect={false}
                // defaultActiveStartDate
                selectRange={false}
                showDoubleView={ false }
                view='month'
                tileDisabled={({ activeStartDate, date, view }) => handleDateDisable(date)}
            />}
        </div >
    );
}

export default SmallScreenClnder