import { useFormikContext } from "formik";
import { useEffect } from "react";

const FormObserver = ({handleFormChange,departureData}) => {
    const { values } = useFormikContext();
  
    useEffect(() => {
        handleFormChange?.();
    }, [values,departureData?.trip_itinerary, departureData?.bike_hire, departureData?.rooms]);
  
    return null;
  };

  export default FormObserver;