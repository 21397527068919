import React from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css'
import "./index.css"
import moment from 'moment/moment';
const Calander = ({ onChange, value, tileClassName, selectedTriplength, maxDate, minDate}) => {

    const handleDateDisable = (date) => {
        return !(selectedTriplength?.available_dates?.find(d => moment(date).isSame(moment(d))))
    }

    return (
        <div className='relative'>
        {selectedTriplength && (
          <Calendar
            onClickDay={onChange}
            value={moment(value)}
            prev2Label={null}   
            next2Label={null}
            tileClassName={tileClassName}
            showNavigation={true}
            calendarType={"US"}
            locale={"sv"}
            maxDate={new Date(maxDate)}
            minDate={new Date(minDate)}
            navigationAriaLive="off"
            showNeighboringMonth={false}
            showFixedNumberOfWeeks={false}
            showWeekNumbers={false}
            goToRangeStartOnSelect={false}
            selectRange={false}
            showDoubleView={true}
            view='month'
            tileDisabled={({ activeStartDate, date, view }) => handleDateDisable(date)}
          />
          )}
             {selectedTriplength && <hr className="text-gray100 w-[38%] mx-auto absolute top-1/2 left-0 right-0 transform -translate-x-1/2" style={{ transform: "rotate(90deg)", marginTop: "19px", marginBottom: "-13px" }} />}
        
      </div>
    );
}

export default Calander