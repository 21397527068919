import React from 'react'

const Avatar = ({logo,heading}) => {
  return (
    <div className='flex items-center pt-3 sm:pb-0'>
      <div className='w-11 h-11 rounded-full bg-lightGreen flex items-center mr-3' >
        <img src={logo} alt="" className='mx-auto' />
      </div>
      <h1 className='leading-7 text-[1.4rem] md:text-2xl text-green font-bold'>{heading}</h1>
    </div>
  )
}

export default Avatar